body {
  margin: 0;
  padding-bottom: 2em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #2f323b; */
}

.catch-padding {
  padding-bottom: 10em;
}

.messages {
  margin-top: 3em;
}

.wrapper{
  display: flex;
  justify-content: space-between;
}
#filler {
  position: fixed;
  z-index: -1;
  display: flex;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  color: black;
  text-align: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  height: 100%;
  background-attachment: fixed;
}

#login {
  display: flex;
  justify-content: center;
}

#acc {
  color: white;
}

#sign-in-form {
  top: 19em;
}
#form {
  position: fixed;
  bottom: 2em;
  left: 3em;
}

.image-border {
  border-radius: 10% !important;
}

#next {
  position: fixed;
  bottom:4em;
  box-shadow: 3px 3px 3px #aaaaaa;
  left: 1em;
}

#sign-in-bg {
  height: 80vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: contain;
  z-index: -2;
  background-color: #2f323b;
}

#bg-img {
  width: 100%;
}

footer {
  position: fixed;
  display: flex;
  justify-content: space-between;
  left: 0;
  bottom: 0;
  height: 10%;
  padding: 24px 24px 24px 24px;
  width: 100%;
  color: black;
  background: #eeeeee;
  text-align: center;
}

a {
  color: black;
}

a:hover {
  color: #e43b72;
}

.bubble {
  position: relative;
  padding: 1em;
  width: 75%;
  box-shadow: 3px 3px 3px #aaaaaa;
  background: rgb(226, 226, 226);
  border-radius: 0.6em;
}

.bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: rgb(226, 226, 226);
  border-left: 0;
  border-top: 0;
  margin-top: -10px;
  margin-left: -20px;
}

.bubble-user {
  position: relative;
  padding: 1em;
  width: 75%;
  left: 5em;
  background: lightgreen;
  box-shadow: 3px 3px 3px #aaaaaa;
  border-radius: 0.6em;
}

.bubble-user:after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: lightgreen;
  border-right: 0;
  border-top: 0;
  margin-top: -10px;
  margin-right: -20px;
}

#box-header {
  position: fixed;
  opacity: 0.8;
  top: 0;
  z-index: 1;
  width: 100%;
}
.icon-wrapper {
  position: relative;
  float: left;
}

/* *.icon-blue {
  color: white;
}
*.icon-grey {
  color: white;
} */
i {
  width: 10em;
  text-align: center;
  vertical-align: middle;
}
.badge {
  background: radial-gradient(5px -9px, circle, white 8%, red 26px);
  background-color: red;
  border: 2px solid white;
  border-radius: 20px; /* one half of ( (border * 2) + height + padding ) */
  box-shadow: 1px 1px 1px black;
  color: white;
  font: bold 15px/13px Helvetica, Verdana, Tahoma;
  width: 1.8em;
  height: 1.8em;
  margin: 0;
  border-radius: 60%;
  position: absolute;
  top: -14px;
  right: -25px;
  padding: 10px;
  box-shadow: 2px 2px 1px grey;
  padding: 4px 3px 0 3px;
  text-align: center;
}

.badge-1 {
  background: radial-gradient(5px -9px, circle, white 8%, red 26px);
  background-color: red;
  border: 2px solid white;
  border-radius: 20px; /* one half of ( (border * 2) + height + padding ) */
  box-shadow: 1px 1px 1px black;
  color: white;
  font: bold 15px/13px Helvetica, Verdana, Tahoma;
  width: 1.8em;
  height: 1.8em;
  margin: 0;
  border-radius: 60%;
  position: absolute;
  top: -14px;
  right: -23px;
  padding: 10px;
  box-shadow: 2px 2px 1px grey;
  padding: 4px 3px 0 3px;
  text-align: center;
}
